import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDoK9FP1Y3Bosg1LfXSXyC18YklPlm4oYU",
  authDomain: "tovagliette-7a81c.firebaseapp.com",
  projectId: "tovagliette-7a81c",
  storageBucket: "tovagliette-7a81c.appspot.com",
  messagingSenderId: "179271626836",
  appId: "1:179271626836:web:47c1261465c67b4ffc7dc1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app)

// Auth
const auth = getAuth(app)

export {db, auth}
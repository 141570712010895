import React, { useState } from 'react'
import Constants from '../utilities/Constants'
import FormButton from "../components/formButtons/updateButton";

export default function PersonUpdateForm(props) {
    const initialFormData = Object.freeze({
        personId: props.person._id,
        name: props.person.name,
        surname: props.person.surname,
        gender: props.person.gender,
        email: props.person.email,
        dateOfBirth: props.person.dateOfBirth,
        website: props.person.website,
        clicks: props.person.clicks,
    });

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const personToUpdate = {
            _id: props.person._id,
            name: formData.name,
            surname: formData.surname,
            gender: formData.gender,
            email: formData.email,
            dateOfBirth: formData.dateOfBirth,
            website: formData.website,
            clicks: formData.clicks,
        };

        const url = Constants.API_URL_UPDATE_PERSON;

        fetch(url + '/' + personToUpdate._id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(personToUpdate)
        })
            .then(response => response.json())
            .then(responseFromServer => {
                console.log(responseFromServer);
            })
            .catch((error) => {
                console.log(error);
                alert(error);
            });

        props.onPersonUpdated(personToUpdate);
    };

    return (
        <form className="w-100 px-5">
            <h1 className="mt-5">Updating the websit "{props.person.website}".</h1>

            <div className="mt-5">
                <label className="h3 form-label">Website</label>
                <input value={formData.website} name="website" type="text" className="form-control" onChange={handleChange} />
            </div>

            <div className="mt-5">
                <label className="h3 form-label">Clicks</label>
                <input value={formData.clicks} name="clicks" type="text" className="form-control" onChange={handleChange} />
            </div>

            <div className="mt-5 d-none">
                <label className="h3 form-label">Name</label>
                <input value={formData.website} name="name" type="text" className="form-control" onChange={handleChange} />
            </div>

            <div className="mt-4 d-none">
                <label className="h3 form-label">Surname</label>
                <input value={formData.clicks} name="surname" type="text" className="form-control" onChange={handleChange} />
            </div>

            <div className="mt-4 d-none">
                <label className="h3 form-label">Sesso (F/M)</label>
                <input value={formData.gender} name="gender" type="text" className="form-control" onChange={handleChange} />
            </div>

            <div className="mt-4 d-none">
                <label className="h3 form-label">Indirizzo e-mail</label>
                <input value={formData.email} name="email" type="email" className="form-control" onChange={handleChange} pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}" />
            </div>

            <div className="mt-4 d-none">
                <label className="h3 form-label">Data di nascita</label>
                <input value={formData.dateOfBirth} name="dateOfBirth" type="date" className="form-control" onChange={handleChange} />
            </div>

            {/* <FormButton onClick={handleSubmit} label="Invia" className="btn btn-dark btn-lg w-100 mt-5" /> */}
            <button onClick={handleSubmit} className="btn btn-dark btn-lg w-100 mt-5">Invia</button>
            <FormButton onClick={() => props.onPersonUpdated(null)} label="Annulla" className="btn btn-secondary btn-lg w-100 mt-3" />
        </form>
    );
}

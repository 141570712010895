import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Constants from "./utilities/Constants";
import PersonCreateForm from "./components/PersonCreateForm";
import PersonUpdateForm from "./components/PersonUpdateForm";
import PersonUpdateClicksForm from "./components/PersonUpdateClicksForm";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormButton from "./components/formButtons/updateButton";
import { axiosInstance } from "./utilities/axios";
import { addDoc, collection, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "./utilities/firebase";

export default function App() {
  const [persons, setPersons] = useState([]);
  const [showingCreateNewPersonForm, setShowingCreateNewPersonForm] = useState(false);
  const [personCurrentlyBeingUpdated, setPersonCurrentlyBeingUpdated] = useState(null);
  const [search, setSearch] = useState('');
  const [createLink, setCreateLink] = useState('');
  const [showMainContent, setShowMainContent] = useState('d-none');
  const [showPassword, setShowPassword] = useState('');

  async function getPersons () {

    const allPersons = await getDocs(collection(db, "persons"))
    const persons = allPersons.docs.map(doc => {
      return {
        _id: doc.id,
        ...doc.data()
      }
    })
    
    setPersons(persons)

    /*const url = Constants.API_URL_GET_ALL_PERSONS;

    fetch(url, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(personsFromServer => {
        console.log(personsFromServer)
        setPersons(personsFromServer);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });*/
  }

  function deletePerson(personId) {
    const url = `${Constants.API_URL_DELETE_PERSON_BY_ID}/${personId}`;

    console.log('Innn');

    fetch(url, {
      method: 'DELETE'
    })
      .then(response => response.json())
      .then(responseFromServer => {
        console.log(responseFromServer);
        onPersonDeleted(personId);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  }

var url_string = window.location.href; 
var url = new URL(url_string);
var c = url.searchParams.get("q");

useEffect(() => {
  const updateOrCreatePerson = async () => {
    if (c){
      const q = query(collection(db, 'persons'), where("website", "==", c))
      const persons = await getDocs(q)

      if(persons.empty){
        await addDoc(collection(db, 'persons'), { website: c, clicks: 1 })

        window.location.href = c;
        return true
      }

      const personDoc =  persons.docs[0]
      const personRef = personDoc.ref

      await updateDoc(personRef, {
        clicks: personDoc.data().clicks + 1
      })

      window.location.href = c;

      return true

     
  }
}

  updateOrCreatePerson()
}, [])


/*
if(c){

  axiosInstance.post(`/persons/create-or-updates?q=${c}`)
  .then(() => window.location.href = c )
  .catch(err => console.log(err))
 
  return <></>
}
*/
//console.log(c);

function CopyCreatedLink() {
  // Get the text field
  var copyText = document.getElementById("linkCreato");

  // Select the text field
  copyText.select();
  copyText.setSelectionRange(0, 99999); // For mobile devices

   // Copy the text inside the text field
  navigator.clipboard.writeText(copyText.value);

  // Alert the copied text
  alert("QR Link copiato");
}

function CheckPassword(){
  if(document.getElementById('password').value == 'Tramontana231'){
    setShowMainContent('');
    setShowPassword('d-none');
    getPersons();
  }
}

function CheckKeyForPassword(event){
if(event.key == 'Enter') {
  CheckPassword();
}
}

if (c){
  return <></>
}

  return (
    <div className="container">
      <div className="row min-vh-100">
        <div className="col d-flex flex-column justify-content-center align-items-center">
          {(showingCreateNewPersonForm === false && personCurrentlyBeingUpdated === null) && (

            // Div to show first form
            <div>
              
            <div id="formPassword" className={showPassword}>
              Password:
            <InputGroup className='my-3'>
              {/* onChange for search */}
              <Form.Control
                onKeyUp={CheckKeyForPassword}
                placeholder='PASSWORD'
                id="password"
              />
            </InputGroup>
            <button onClick={CheckPassword} label="Invia" className="btn btn-dark btn-lg w-100" >Invia</button>
          </div>

          <div className={showMainContent} id="mainContent">
              <h1>Tovagliette Catania</h1>

              <div className="mt-5">
                <FormButton onClick={getPersons} label="Mostra l'elenco" className="btn btn-dark btn-lg w-100 d-none" />
                <FormButton onClick={() => setShowingCreateNewPersonForm(true)} label="Crea una nuova persona" className="btn btn-secondary btn-lg w-100 mt-4 d-none" />
              </div>
              <Form>
              <InputGroup className='my-3'>
                {/* onChange for search */}
                <Form.Control
                  onChange={(e) => setCreateLink('https://tovagliettecatania.it/?q='+e.target.value.toLowerCase())}
                  placeholder='Crea qui il link..'
                  id="creaQuiLink"
                />
              </InputGroup>
            </Form>
            <input type="text" id="linkCreato" value={createLink} className="form-control input-lg" />
              <button className="btn btn-dark btn-lg w-100" label="COPIA LINK QR" onClick={CopyCreatedLink} >COPIA LINK QR</button>
            </div>
          </div>
          )}

          {(persons.length > 0 && showingCreateNewPersonForm === false && personCurrentlyBeingUpdated === null) && renderPersonsTable()}

          {showingCreateNewPersonForm && <PersonCreateForm onPersonCreated={onPersonCreated} />}

          {personCurrentlyBeingUpdated !== null && <PersonUpdateForm person={personCurrentlyBeingUpdated} onPersonUpdated={onPersonUpdated} />}
        </div>
      </div>
    </div>
  );

  function renderPersonsTable() {
    return (
      <Container>
        <div className="table-responsive mt-5">
          <Form>
            <InputGroup className='my-3'>
              {/* onChange for search */}
              <Form.Control
                onChange={(e) => setSearch(e.target.value.toLowerCase())}
                placeholder='Cerca un sito web...'
              />
            </InputGroup>
          </Form>
          <Table table table-bordered border-dark>
            <thead>
              <tr>
                <th scope="col">Website</th>
                <th scope="col">Clicks</th>
                <th scope="col">CRUD Operations</th>
              </tr>
            </thead>
            <tbody>
              {[].concat(persons)
                .sort((a, b) => a.website > b.website ? 1 : -1)

                .filter((persons) => {
                  return search.toLowerCase() === ''
                    ? persons
                    : persons.website.toLowerCase().includes(search);
                })

                .map((person) => (
                  <tr key={person._id}>
                    <td>{person.website}</td>
                    <td>{person.clicks}</td>
                    <td>
                      <FormButton onClick={() => CreaStampa(person.website, person.clicks)} label="Crea Stampa" className="btn btn-dark btn-lg mx-3 my-3" />
                      <FormButton onClick={() => setPersonCurrentlyBeingUpdated(person)} label="Update" className="btn btn-dark btn-lg mx-3 my-3 d-none" />
                      <FormButton onClick={() => { if (window.confirm(`Are you sure you want to delete the Person titled "${person.website} ${person.clicks}"?`)) deletePerson(person._id) }} label="Delete" className="btn btn-secondary btn-lg d-none" />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <FormButton onClick={() => setPersons([])} label="Nasconde elenco" className="btn btn-dark btn-lg w-100 d-none" />
        </div>
      </Container>
    );
  }

  function GetAgeFromBirth(birthday) {
    var dob = new Date(birthday);
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date    
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    var age = Math.abs(year - 1970);

    return age
  }

  function onPersonCreated(createdPerson) {
    setShowingCreateNewPersonForm(false);

    if (createdPerson === null) {
      return;
    }

    // alert(`Person successfully created. After clicking OK, your new person tilted "${createdPerson.title}" will show up in the table below.`);

    getPersons();
  }

  function onPersonUpdated(updatedPerson) {
    setPersonCurrentlyBeingUpdated(null);

    if (updatedPerson === null) {
      return;
    }

    let personsCopy = [...persons];

    const index = personsCopy.findIndex((personsCopyPerson, currentIndex) => {
      if (personsCopyPerson._id === updatedPerson._id) {
        return true;
      }
    });

    if (index !== -1) {
      personsCopy[index] = updatedPerson;
    }

    setPersons(personsCopy);

    // alert(`Person successfully updated. After clicking OK, look for the person with the title "${updatedPerson.title}" in the table below to see the updates.`);
  }

  function onPersonDeleted(deletedPersonPersonId) {
    let personsCopy = [...persons];

    const index = personsCopy.findIndex((personsCopyPerson, currentIndex) => {
      if (personsCopyPerson.ersonId === deletedPersonPersonId) {
        return true;
      }
    });

    if (index !== -1) {
      personsCopy.splice(index, 1);
    }

    setPersons(personsCopy);

    // alert('Person successfully deleted. After clicking OK, look at the table below to see your person disappear.');
  }

  function CreaStampa(website, clicks){
    setCookie('website', website, 1);
    setCookie('clicks', clicks, 1);
    window.open('stampa.htm');
  }

  function setCookie(key, value, expiry) {
    var expires = new Date();
    expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
}
}

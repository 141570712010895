import React, { useState } from 'react'
import Constants from '../utilities/Constants'
import FormButton from "./formButtons/updateButton";

export default function PersonCreateForm(props) {
    const initialFormData = Object.freeze({
        title: "Person x",
        content: "This is person x and it has some very interesting content. I have also liked the video and subscribed."
    });

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const personToCreate = {
            personId: 0,
            name: formData.name,
            surname: formData.surname,
            gender: formData.gender,
            email: formData.email,
            dateOfBirth: formData.dateOfBirth,
            website: formData.website,
            clicks: formData.clicks,
        };

        const url = Constants.API_URL_CREATE_PERSON;

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(personToCreate)
        })
            .then(response => response.json())
            .then(responseFromServer => {
                console.log(responseFromServer);
            })
            .catch((error) => {
                console.log(error);
                alert("Error: " + error);
            });
        props.onPersonCreated(personToCreate);
    };

    return (
        <form className="w-100 px-5">
            <h1 className="mt-5">Crea nuova persona</h1>
            <div className="mt-5">
                <label className="h3 form-label">Website</label>
                <input value={formData.website} name="website" type="text" className="form-control" onChange={handleChange} />
            </div>
            <div className="mt-5">
                <label className="h3 form-label">Clicks</label>
                <input value={formData.clicks} name="clicks" type="text" className="form-control" onChange={handleChange} />
            </div>

            <div className="mt-5">
                <label className="h3 form-label">Nome</label>
                <input value={formData.name} name="name" type="text" className="form-control" onChange={handleChange} />
            </div>

            <div className="mt-4">
                <label className="h3 form-label">Cognome</label>
                <input value={formData.surname} name="surname" type="text" className="form-control" onChange={handleChange} />
            </div>

            <div className="mt-4">
                <label className="h3 form-label">Sesso (F/M)</label>
                <input value={formData.gender} name="gender" type="text" className="form-control" onChange={handleChange} />
            </div>

            <div className="mt-4">
                <label className="h3 form-label">Indirizzo e-email</label>
                <input value={formData.email} name="email" type="text" className="form-control" onChange={handleChange} />
            </div>

            <div className="mt-4">
                <label className="h3 form-label">Data di nascita</label>
                <input value={formData.dateOfBirth} name="dateOfBirth" type="date" className="form-control" onChange={handleChange} />
            </div>

            { /* <FormButton onClick={handleSubmit} label="Invia" className="btn btn-dark btn-lg w-100" /> */ }
            <button onClick={handleSubmit} className="btn btn-dark btn-lg w-100 mt-5">Invia</button>
            <FormButton onClick={() => props.onPersonCreated(null)} label="Annulla" className="btn btn-secondary btn-lg w-100 mt-3" />
        </form>
    );
}

const API_BASE_URL_DEVELOPMENT = 'http://localhost:8000';
const API_BASE_URL_PRODUCTION = 'https://jogroup.eu';

const ENDPOINTS = {
    GET_ALL_PERSONS: 'api/persons',
    GET_PERSONS_BY_ID: 'api/persons',
    CREATE_PERSON: 'api/persons',
    UPDATE_PERSON: 'api/persons',
    DELETE_PERSON_BY_ID: 'api/persons'
};

const development = {
    API_URL_GET_ALL_PERSONS: `${API_BASE_URL_DEVELOPMENT}/${ENDPOINTS.GET_ALL_PERSONS}`,
    API_URL_GET_PERSONS_BY_ID: `${API_BASE_URL_DEVELOPMENT}/${ENDPOINTS.GET_PERSON_BY_ID}`,
    API_URL_CREATE_PERSON: `${API_BASE_URL_DEVELOPMENT}/${ENDPOINTS.CREATE_PERSON}`,
    API_URL_UPDATE_PERSON: `${API_BASE_URL_DEVELOPMENT}/${ENDPOINTS.UPDATE_PERSON}`,
    API_URL_DELETE_PERSON_BY_ID: `${API_BASE_URL_DEVELOPMENT}/${ENDPOINTS.DELETE_PERSON_BY_ID}`,
};

const production = {
    API_URL_GET_ALL_PERSONS: `${API_BASE_URL_PRODUCTION}/${ENDPOINTS.GET_ALL_PERSONS}`,
    API_URL_GET_PERSONS_BY_ID: `${API_BASE_URL_PRODUCTION}/${ENDPOINTS.GET_PERSON_BY_ID}`,
    API_URL_CREATE_PERSON: `${API_BASE_URL_PRODUCTION}/${ENDPOINTS.CREATE_PERSON}`,
    API_URL_UPDATE_PERSON: `${API_BASE_URL_PRODUCTION}/${ENDPOINTS.UPDATE_PERSON}`,
    API_URL_DELETE_PERSON_BY_ID: `${API_BASE_URL_PRODUCTION}/${ENDPOINTS.DELETE_PERSON_BY_ID}`,
};

const Constants = process.env.NODE_ENV === 'development' ? development : production;

export default Constants;
